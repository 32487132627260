锘?/鑳屾櫙鐢ㄨ壊
$color-main:#01916d; //涓婚缁胯壊
$color-main-dark:#026d52;
$color-main-light:#f2f4f8; 
$color-main-bg:#eee;

//border鐢ㄨ壊
$color-line:#b2b2b2;

//鏂囧瓧鐢ㄨ壊
$color-red:#ff0909; //閿欒鎻愮ず鏂囧瓧棰滆壊
$color-title:#000; //鏍囬榛戣壊
$color-text:#585858; //姝ｆ枃棰滆壊  80%榛慭r
$color-desc:#999999; //瑙ｉ噴鎬ф枃瀛楅鑹  60%榛慭r

//鏂囧瓧澶у皬
$text-size-sm-base:14px;
$text-size-sm-ratio:12 / 14;

$text-size-base:16px; //姝ｆ枃澶у皬
$text-size-ratio:(lg:14px); //缂╂斁

$text-size-md-base:18px; //鍒楄〃澶存潯鍐呭鏂囧瓧澶у皬
$text-size-md-ratio:(lg:16px, mmd:14px); //缂╂斁

$text-size-lg-base:20px;
$text-size-lg-ratio:(lg:18px, mmd:16px);

//tag鏂囧瓧澶у皬
$tag-size-base: 16px;
$tag-size-ratio: 14 / 16;

//鏍囬澶у皬锛岀敱灏忓埌澶r
$title-size-xs-base:22px;
$title-size-xs-ratio:(lg:20px, mmd:18px, md:16px);

$title-size-sm-base:26px;
$title-size-sm-ratio:(lg:22px, mmd:20px, md:16px);

$title-size-md-base:30px;
$title-size-md-ratio:(lg:26px, mmd:22px, md:18px);

$title-size-lg-base:34px;
$title-size-lg-ratio:(lg:30px, mmd:26px, md:18px);

// 鍔ㄧ敾鏁堟灉鎸佺画鏃堕棿
$anime-duration: 0.4s;
//璐濆灏旀洸绾縗r
$anime-bezier:cubic-bezier(0.77, 0, 0.175, 1);

//header楂樺害
$header-height-base: 135px;
$header-height-ratio: 60 / 135;

//鍐呭閮ㄥ垎宸﹀彸鐣欑櫧
$container-gutter-base:80px;
$container-gutter-ratio:10 / 80;

//闃村奖
$shadow-offset: 0px 3px;
$shadow-spread: 10px;
$shadow-color:#dbdada;

$jason-map-width: 400px !default;
$jason-map-duration: .5s !default;
$jason-map-theme: #ddd0b5 !default;

//姹夊牎鍖呮寜閽弬鏁拌缃甛r
$hamburger-layer-width: 28px; //姹夊牎鍖呮寜閽瘡涓€鏉＄嚎鐨勫搴r
$hamburger-layer-height: 3px; //姹夊牎鍖呮寜閽瘡涓€鏉＄嚎鐨勯珮搴r
$hamburger-layer-spacing: 5px; //姹夊牎鍖呮寜閽瘡涓€鏉＄嚎鐨勯珮搴﹂棿闅擻r
$hamburger-layer-color: $color-main; //姹夊牎鍖呮寜閽嚎鐨勯鑹瞈r
$hamburger-layer-border-radius: 0; //姹夊牎鍖呮寜閽嚎鐨勫渾瑙掓晥鏋淺r

// 鍝嶅簲寮忔柇鐐癸紝闇€瑕佷粠灏忓埌澶у啓
$layout-responsive-breakpoint: ( // 鎵嬫満
  xs: (min-width: 0px, container-width: 100%),
  // 骞虫澘
  sm: (min-width: 768px, container-width: 720px),
  // 灏忓睆
  md: (min-width: 992px, container-width: 960px),
  // 涓睆
  mmd: (min-width: 1025px, container-width: 1000px),
  // 澶у睆
  lg: (min-width: 1367px, container-width: 1230px),

  llg: (min-width: 1601px, container-width: 1230px)) !default;
// 姹夊牎鍖匼r
.hamburger {
  display: block;
  cursor: pointer;
  background-color: transparent;
  overflow: visible;
  position: relative;
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  > div, > view {
    display: block;
    top: 50%;
    margin-top: $hamburger-layer-height / -2;
    &,
    &::before,
    &::after {
      width: $hamburger-layer-width;
      height: $hamburger-layer-height;
      background-color: #1B8CAC;
      border-radius: $hamburger-layer-border-radius;
      position: absolute;
      transition-property: all;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }
    &::before,
    &::after {
      content: "";
      display: block;
    }
    &::before {
      top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
    &::after {
      bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
  }
  &.hamburger-1 {
    > div, > view {
      top: auto;
      bottom: 0;
      transition-duration: 0.15s;
      transition-delay: 0.15s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      &::after {
        top: ($hamburger-layer-spacing * 2 + $hamburger-layer-height * 2) * -1;
        transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        opacity 0.1s linear, background-color 0.3s linear;
      }
      &::before {
        transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
      }
    }
    &.active {
      > div, view {
        transform: translate3d(0, ($hamburger-layer-spacing + $hamburger-layer-height) * -1, 0) rotate(-45deg);
        transition-delay: 0.32s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::after {
          top: 0;
          opacity: 0;
          transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          opacity 0.1s 0.27s linear, background-color 0.3s linear;
        }

        &::before {
          top: 0;
          transform: rotate(-90deg);
          transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
        }
      }
    }
  }
  &.hamburger-2 {
    > div, > view {
      top: auto;
      bottom: 0;
      transition-duration: 0.15s;
      transition-delay: 0.15s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      &::after {
        top: ($hamburger-layer-spacing * 2 + $hamburger-layer-height * 2) * -1;
        transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        opacity 0.1s linear, background-color 0.3s linear;
      }
      &::before {
        transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
      }
    }
    &.active {
      > div, > view {
        transform: translate3d(0, ($hamburger-layer-spacing + $hamburger-layer-height) * -1, 0) rotate(45deg);
        transition-delay: 0.32s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::after {
          top: 0;
          opacity: 0;
          transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          opacity 0.1s 0.27s linear, background-color 0.3s linear;
        }

        &::before {
          top: 0;
          transform: rotate(90deg);
          transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
        }
      }
    }
  }
  &.hamburger-3 {
    > div, > view {
      top: 0;
      transition-duration: .4s;
      transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
      margin-top: 0;
      &::before {
        top: $hamburger-layer-height+$hamburger-layer-spacing;
        transition: opacity 0.15s 0.4s ease, background-color 0.3s linear;
      }
      &::after {
        top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
        transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), background-color 0.3s linear;
      }
    }
    &.active {
      > div, > view {
        $y-offset: $hamburger-layer-spacing + $hamburger-layer-height;
        transform: translate3d(0, $y-offset, 0) rotate(135deg);
        transition-delay: .1s;
        &::before {
          transition-delay: 0s;
          opacity: 0;
        }
        &:after {
          transform: translate3d(0, $y-offset * -2, 0) rotate(-270deg);
          transition-delay: .1s;
        }
      }
    }
  }
  &.hamburger-4 {
    > div, > view {
      top: 0;
      transition-duration: .4s;
      transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
      margin-top: 0;
      &::before {
        top: $hamburger-layer-height+$hamburger-layer-spacing;
        transition: opacity 0.15s 0.4s ease, background-color 0.3s linear;
      }
      &::after {
        top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
        transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), background-color 0.3s linear;
      }
    }
    &.active {
      > div, > view {
        $y-offset: $hamburger-layer-spacing + $hamburger-layer-height;
        transform: translate3d(0, $y-offset, 0) rotate(-135deg);
        transition-delay: .1s;
        &::before {
          transition-delay: 0s;
          opacity: 0;
        }
        &:after {
          transform: translate3d(0, $y-offset * -2, 0) rotate(270deg);
          transition-delay: .1s;
        }
      }
    }
  }
  &.hamburger-5 {
    > div, > view {
      margin-top: -2px;
      transition: background-color 0.2s 0.25s ease-in, background-color 0.3s linear;
      &::before {
        left: 0;
        transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in, background-color 0.3s linear;
      }
      &::after {
        top: ($hamburger-layer-height) + ($hamburger-layer-spacing);
        right: 0;
        transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in, background-color 0.3s linear;
      }
    }
    &.active {
      > div, > view {
        transition-delay: 0s;
        transition-timing-function: ease-out;
        background-color: transparent;
        &::before {
          left: $hamburger-layer-width * -2;
          top: $hamburger-layer-width * -2;
          transform: translate3d($hamburger-layer-width * 2, $hamburger-layer-width * 2, 0) rotate(45deg);
          transition: left .2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
        }
        &::after {
          right: $hamburger-layer-width * -2;
          top: $hamburger-layer-width * -2;
          transform: translate3d($hamburger-layer-width * -2, $hamburger-layer-width * 2, 0) rotate(-45deg);
          transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
        }
      }
    }
  }
  &.hamburger-6 {
    > div, > view {
      margin-top: -2px;
      transition: background-color 0.2s 0.25s ease-in, background-color 0.3s linear;
      &::before {
        left: 0;
        transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in, background-color 0.3s linear;
      }
      &::after {
        top: ($hamburger-layer-height) + ($hamburger-layer-spacing);
        right: 0;
        transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in, background-color 0.3s linear;
      }
    }
    &.active {
      > div, > view {
        transition-delay: 0s;
        transition-timing-function: ease-out;
        background-color: transparent;
        &::before {
          left: $hamburger-layer-width * -2;
          top: $hamburger-layer-width * 2;
          transform: translate3d($hamburger-layer-width * 2, $hamburger-layer-width * -2, 0) rotate(-45deg);
          transition: left .2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
        }
        &::after {
          right: $hamburger-layer-width * -2;
          top: $hamburger-layer-width * 2;
          transform: translate3d($hamburger-layer-width * -2, $hamburger-layer-width * -2, 0) rotate(45deg);
          transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
        }
      }
    }
  }
  &.hamburger-7 {
    > div, > view {
      top: 0;
      margin-top: 0;
      &::before {
        top: $hamburger-layer-height + $hamburger-layer-spacing;
        transition-property: transform, opacity, background-color;
        transition-timing-function: ease;
        transition-duration: .2s;
      }
      &::after {
        top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
      }
    }
    &.active {
      > div, > view {
        $y-offset: $hamburger-layer-spacing + $hamburger-layer-height;
        transform: translate3d(0, $y-offset, 0) rotate(45deg);
        &::before {
          transform: rotate(-45deg) translate3d($hamburger-layer-width / -7, $hamburger-layer-spacing * -1, 0);
          opacity: 0;
        }
        &::after {
          transform: translate3d(0, $y-offset * -2, 0) rotate(-90deg);
        }
      }
    }
  }
  &.hamburger-8 {
    > div, > view {
      top: 0;
      margin-top: 0;
      &::before {
        top: $hamburger-layer-height + $hamburger-layer-spacing;
        transition-property: transform, opacity, background-color;
        transition-timing-function: ease;
        transition-duration: .2s;
      }
      &::after {
        top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
      }
    }
    &.active {
      > div, > view {
        $y-offset: $hamburger-layer-spacing + $hamburger-layer-height;
        transform: translate3d(0, $y-offset, 0) rotate(-45deg);
        &::before {
          transform: rotate(45deg) translate3d($hamburger-layer-width / -7, $hamburger-layer-spacing * -1, 0);
          opacity: 0;
        }
        &::after {
          transform: translate3d(0, $y-offset * -2, 0) rotate(90deg);
        }
      }
    }
  }
  &.hamburger-9 {
    > div, > view {
      margin-top: -2px;
      transition-duration: .3s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      &::before {
        transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in, background-color 0.3s linear;
      }
      &::after {
        transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
      }
    }
    &.active {
      > div, > view {
        transform: rotate(225deg);
        transition-delay: 0.14s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        &::before {
          top: 0;
          opacity: 0;
          transition: top .1s ease-out, opacity 0.1s 0.14s ease-out, background-color 0.3s linear;
        }
        &::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
        }
      }
    }
  }
  &.hamburger-10 {
    > div, > view {
      margin-top: -2px;
      transition-duration: .3s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      &::before {
        transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in, background-color 0.3s linear;
      }
      &::after {
        transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
      }
    }
    &.active {
      > div, > view {
        transform: rotate(-225deg);
        transition-delay: 0.14s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        &::before {
          top: 0;
          opacity: 0;
          transition: top .1s ease-out, opacity 0.1s 0.14s ease-out, background-color 0.3s linear;
        }
        &::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
        }
      }
    }
  }
  &.hamburger-11 {
    > div, > view {
      margin-top: -2px;
      transition-duration: .1s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      &::before {
        transition: top 0.1s 0.14s ease, opacity 0.1s ease, background-color 0.3s linear;
      }
      &::after {
        transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
      }
    }
    &.active {
      > div, > view {
        transform: rotate(45deg);
        transition-delay: 0.14s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        &::before {
          top: 0;
          opacity: 0;
          transition: top .1s ease, opacity 0.1s 0.14s ease, background-color 0.3s linear;
        }
        &::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
        }
      }
    }
  }
  &.hamburger-12 {
    > div, > view {
      margin-top: -2px;
      transition-duration: .3s;
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      &::before,
      &::after {
        transition-duration: 0s;
        transition-delay: .1s;
        transition-timing-function: linear;
      }
      &::before {
        transition-property: top, opacity, background-color;
      }
      &::after {
        transition-property: bottom, transform, background-color;
      }
    }
    &.active {
      > div, > view {
        transform: rotate(765deg);
        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        &::before,
        &::after {
          transition-delay: 0s;
        }
        &::before {
          top: 0;
          opacity: 0;
        }
        &::after {
          bottom: 0;
          transform: rotate(90deg);
        }
      }
    }
  }
  &.hamburger-13 {
    > div, > view {
      margin-top: -2px;
      transition-duration: .3s;
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      &::before,
      &::after {
        transition-duration: 0s;
        transition-delay: .1s;
        transition-timing-function: linear;
      }
      &::before {
        transition-property: top, opacity;
      }
      &::after {
        transition-property: bottom, transform, background-color;
      }
    }
    &.active {
      > div, > view {
        transform: rotate(-765deg);
        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        &::before,
        &::after {
          transition-delay: 0s;
        }
        &::before {
          top: 0;
          opacity: 0;
        }
        &::after {
          bottom: 0;
          transform: rotate(-90deg);
        }
      }
    }
  }
}