@import "../../assets/styles/utils";
.page{
    width:100%;
    height:100%;
    overflow:hidden;
    position: relative;
    left:0;
    top:0;
}

@media screen and (max-width: 768px) {
    .fp-section {
        height: auto!important;
    }
}

#fp-nav {
    @include res(display,block,(md:none));
    &.right {
        @include res(right,1.6rem,(mmd:1rem));
    }
    ul {
        li {
            border: 1px solid #fff;
        }
    }
    &.on {
        ul {
            li {
                border: 1px solid #595757;
                .active {
                    span {
                        background-color: #1b8cac;
                    }
                }
            }
        }
    }
}

.page1_bg{
    @include res(height,100%,(xs:400px));
    @include res(margin-top,0,(xs:70px));
    position: relative;
    background: #f9f9f9;
    background-size: cover;
    overflow:hidden;
    .swiper {
        width: 100%;
        height: 100%;
        .swiper-slide {
            position: relative;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .text {
                position: absolute;
                color: #ffffff;
                h2 {
                    font-family: PangMenZhengDao;
                    @include res(font-size,0.66rem);
                    span {
                        font-family: SourceHanSansCN-Heavy;
                        color: #1b8cac;
                    }
                }
                p {
                    @include res(margin-top,0.08rem);
                    font-family: SourceHanSansCN-Normal;
                    @include res(font-size,0.24rem,(xs:20px));
                    @include res(letter-spacing,0.12rem);
                }
                .more {
                    @include res(margin-top,0.78rem,(xs:30px));
                    @include res(width,100px);
                    @include res(height,36px);
                    @include res(line-height,36px);
                    border: 1px solid #fff;
                    text-align: center;
                    color: #fff;
                    transition: all .3s;
                    &:hover {
                        background: #1b8cac;
                        border-color: #1b8cac;
                    }
                }
                
                &.text1 {
                    @include res(top,3.5rem,(xs:100px));
                    @include res(left,2.66rem,(xs:2%));
                }
                &.text2 {
                    @include res(top,4.1rem,(xs:130px));
                    @include res(right,2.97rem,(xs:2%));
                    h2 {
                        color: #1b8cac;
                        @include res(font-size,52px,(3 / 5));
                    }
                    p {
                        letter-spacing: normal;
                        @include res(font-size,16px,(14 / 16));
                        @include res(line-height,38px,(4 / 5));
                    }
                }
                &.text3 {
                    @include res(top,3.1rem,(xs:140px));
                    left: 0;
                    right: 0;
                    margin: auto;
                    text-align: center;
                    width: fit-content;
                    h2 {
                        @include res(font-size,80px,(3 / 5));
                        span {
                            color: #fff;
                            font-family: PangMenZhengDao;
                            @include res(margin-right,0.5rem);
                        }
                    }
                    .more {
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }
        .swiper-pagination {
            @include res(bottom,85px,(xs:20px));
            @include res(right,1.6rem,(xs:60px));
            width: fit-content;
            left: auto;
            .swiper-pagination-bullet {
                @include res(width,37px);
                @include res(height,5px);
                background-color: #ffffff;
                opacity: .5;
                border-radius: 0;
                margin: 0;
                margin-right: 1px;
                &:last-child {
                    margin-right: 0;
                }
                &.swiper-pagination-bullet-active {
                    opacity: 1;
                }
            }
        }
    }
}
.page2_bg {
    @include res(height,100%,(xs:550px));
    .bg {
        @include res(position,absolute);
        bottom: 0;
        left: 0;
        width: 100%;
    }
    .content {
        .left {
            @include res(position,absolute,(xs:static));
            @include res(width,396px,(xs:96%));
            @include res(margin,0,(xs:auto));
            @include res(margin-top,0,(xs:30px));
            @include res(top,2rem,(mmd:3rem,xs:40px));
            @include res(left,2.7rem,(mmd:1.6rem,xs:20px));
            p {
                font-family: SourceHanSansCN-Light;
                font-size: 14px;
                line-height: 30px;
                color: #474747;
            }
            .more {
                display: block;
                @include res(margin-top,0.3rem);
                @include res(width,100px);
                @include res(height,36px);
                @include res(line-height,36px);
                border: 1px solid #1b8cac;
                text-align: center;
                color: #1b8cac;
                transition: all .3s;
                &:hover {
                    color: #fff;
                    background: #1b8cac;
                    border-color: #1b8cac;
                }
            }
        }
        .right {
            @include res(position,absolute,(xs:static));
            @include res(width,218px);
            @include res(margin,0,(xs:auto));
            @include res(margin-top,0,(xs:30px));
            @include res(top,2.07rem,(mmd:3.04rem));
            @include res(right,3.72rem,(mmd:2rem));
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            li {
                width: 50%;
                @include res(height,0.8rem);
                display: flex;
                position: relative;
                align-items: center;
                a {
                    display: block;
                    width: fit-content;
                    .pic {
                        width: fit-content;
                        margin: auto;
                        .img2 {
                            display: none;
                            transition: all .3s;
                        }
                    }
                    span {
                        margin-top: 7px;
                        width: fit-content;
                        display: block;
                        @include res(font-size,16px,(14 / 16));
                        line-height: 30px;
                        color: #929292;
                        transition: all .3s;
                    }
                    &:hover {
                        .pic {
                            .img1 {
                                display: none;
                            }
                            .img2 {
                                display: block;
                                transform: scale(1.1);
                            }
                        }
                        span {
                            color: #1b8cac;
                            transform: scale(1.1);
                        }
                    }
                }
                &:nth-child(2n-1) {
                    border-right: 1px solid #eee;
                    @include res(padding-right,39px);
                }
                &:first-child {
                    @include res(margin-bottom,48px);
                }
                &:nth-child(2) {
                    @include res(margin-bottom,48px);
                    @include res(padding-left,26px);
                    .line {
                        @include res(left,26px);
                    }
                }
                &:nth-child(4) {
                    @include res(padding-left,39px);
                }
                .line {
                    position: absolute;
                    @include res(width,80px);
                    height: 1px;
                    background-color: #eeeeee;
                    @include res(left,0);
                    @include res(bottom,-17px);
                }
            }
        }
    }
}
.page3_bg {
    @include res(height,100%,(xs:450px));
    background: url(./images/pro3-bg.png)no-repeat;
    background-size: cover;
    .pro3-nav {
        @include res(position,absolute,(xs:relative));
        @include res(top,4.9rem,(xs:30px));
        @include res(left,4.45rem,(xs:0));
        z-index: 10;
        @include res(display,block,(xs:flex));
        align-items: center;
        justify-content: center;
        li {
            cursor: pointer;
            font-family: SourceHanSansCN-Light;
            @include res(font-size,18px);
            color: #ffffff;
            opacity: 0.5;
            transition: all .3s;
            @include res(margin-bottom,25px,(xs:0));
            @include res(margin-right,0,(xs:10px));
            &:last-child {
                margin-bottom: 0;
                margin-right: 0;
            }
            &.active {
                opacity: 1;
                border-left: 1px solid #fff;
            }
                border-left: 1px solid transparent;
            @include res(padding-left,19px,(xs:10px));
        }
    }
    .pro3-lunbo {
        @include res(position,absolute,(xs:relative));
        @include res(top,2.1rem,(xs:60px));
        left: 0;
        right: 0;
        margin: auto;
        h2 {
            font-family: SourceHanSansCN-Light;
            @include res(font-size,0.36rem);
            letter-spacing: 3px;
            text-align: center;
            color: #fff;
            @include res(margin-bottom,1.3rem,(xs:30px));
            span {
                font-family: SourceHanSansCN-Bold;
            }
        }
        img {
            margin: auto;
            @include res(width,fit-content);
        }
        .pro3-swiper {
            display: none;
            &.active {
                display: block;
            }
            .swiper {
                .swiper-slide {
                    img {
                        margin: auto;
                    }
                }
            }
            .swiper-button-next {
                @include res(right,2.7rem,(mmd:1.6rem,xs:20px));
                .img2 {
                    display: none;
                }
                &:hover,&.active {
                    .img1 {
                        display: none;
                    }
                    .img2 {
                        display: block;
                    }
                }
            }
            .swiper-button-prev {
                @include res(left,2.7rem,(mmd:1.6rem,xs:20px));
                .img2 {
                    display: none;
                }
                &:hover,&.active {
                    .img1 {
                        display: none;
                    }
                    .img2 {
                        display: block;
                    }
                }
            }
        }
    }
}
.page4_bg {
    @include res(height,100%,(xs:400px));
    @include res(padding-bottom,0,(xs:40px));
    .pro4-bg {
        width: 100%;
        height: 100%;
        @include res(position,relative,(xs:absolute));
        top: 0;
        left: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: none;
            &.active {
                display: block;
            }
        }
    }
    .pro4-text {
        @include res(position,absolute,(xs:relative));
        @include res(width,466px,(xs:96%));
        @include res(top,2.7rem,(xs:30px));
        @include res(left,2.7rem,(mmd:1.6rem,xs:0));
        @include res(right,auto,(xs:0));
        @include res(margin,0,(xs:auto));
        .text {
            display: none;
            &.active {
                display: block;
            }
            h2 {
                font-family: SourceHanSansCN-Medium;
                @include res(font-size,20px);
                color: #474747;
            }
            p {
                @include res(margin-top,15px);
                font-family: SourceHanSansCN-Light;
                font-size: 14px;
                line-height: 30px;
                color: #474747;
            }
            .more {
                display: block;
                @include res(margin-top,0.4rem);
                @include res(width,100px);
                @include res(height,36px);
                @include res(line-height,36px);
                border: 1px solid #1b8cac;
                text-align: center;
                color: #1b8cac;
                transition: all .3s;
                &:hover {
                    color: #fff;
                    background: #1b8cac;
                    border-color: #1b8cac;
                }
            }
        }
    }
    .pro4-nav {
        @include res(position,absolute);
        @include res(bottom,1.53rem,(xs:20px));
        @include res(left,2.7rem,(mmd:1.6rem,xs:0));
        @include res(right,auto,(xs:0));
        display: flex;
        align-items: center;
        @include res(justify-content,flex-start,(xs:center));
        li {
            @include res(width,85px);
            @include res(height,85px);
            @include res(margin-right,0.14rem);
            background: #fff;
            transition: all .3s;
            a {
                display: flex;
                flex-direction:column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
            }
            .pic {
                @include res(margin-bottom,0.1rem);
                .img2 {
                    display: none;
                }
            }
            span {
                display: block;
                text-align: center;
                @include res(font-size,16px,(14 / 16));
                color: #474747;
            }
            &.active {
                background: #1b8cac;
                .pic {
                    .img1 {
                        display: none;
                    }
                    .img2 {
                        display: block;
                    }
                }
                span {
                    color: #fff;
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.page5_bg {
    background: url(./images/pro5-bg.png)no-repeat;
    background-size: cover;
    @include res(height,100%,(xs:auto));
    @include res(padding-bottom,0,(xs:30px));
    .pro5-news {
        @include res(position,absolute,(xs:static));
        @include res(left,2.7rem,(mmd:1.6rem));
        @include res(top,2.88rem,(mmd:50%));
        @include res(width,13.8rem,(mmd:16rem,xs:96%));
        @include res(transform,translateY(0),(mmd:translateY(-50%),xs:translateY(0)));
        .news {
            &.active {
                display: flex;
            }
            .pic {
                @include res(display,block,(xs:none));
                @include res(width,6.9rem,(mmd:50%));
                @include res(height,5.09rem);
                a {
                    width: 100%;
                    height: 100%;
                    display: none;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    &.active {
                        display: block;
                    }
                }
            }
            ul {
                @include res(width,7.83rem,(mmd:56%,xs:96%));
                @include res(position,absolute,(xs:static));
                @include res(bottom,0);
                @include res(right,0);
                @include res(margin,0,(xs:auto));
                @include res(margin-top,0,(xs:30px));
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                li {
                    @include res(width,6.28rem,(mmd:80%,xs:100%));
                    @include res(padding-bottom,0.3rem);
                    @include res(margin-bottom,0.34rem);
                    border-bottom: 1px solid rgba(89,87,87,1.000);
                    transition: all .5s;
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .text {
                            @include res(width,5.24rem,(mmd:80%));
                            .top {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                h2 {
                                    @include res(width,3.8rem,(mmd:72.5%));
                                    font-family: SourceHanSansCN-Regular;
                                    @include res(font-size,0.18rem);
                                    color: #474747;
                                    margin-bottom: 5px;
                                }
                                .time {
                                    font-family: SourceHanSansCN-Light;
                                    @include res(font-size,0.14rem);
                                    color: #5f5f5f;
                                }
                            }
                            p {
                                font-family: SourceHanSansCN-Light;
                                @include res(font-size,0.14rem);
                                @include res(line-height,24px);
                                color: #5f5f5f;
                                opacity: 0.8;
                                overflow:hidden;
                                text-overflow: ellipsis;
                                -webkit-line-clamp: 2;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                            }
                        }
                        .more {
                            .img2 {
                                display: none;
                            }
                        }
                        .time-box {
                            display: none;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                        border: none;
                    }
                    &.active {
                        @include res(width,7.83rem,(mmd:100%));
                        @include res(height,1.6rem);
                        background-color: #ffffff;
                        border: none;
                        box-sizing: border-box;
                        @include res(padding-left,0.13rem);
                        @include res(padding-right,0.28rem);
                        a {
                            .time-box {
                                display: block;
                                @include res(width,1.19rem);
                                @include res(height,1.45rem);
                                background-color: #1b8cac;
                                display: flex;
                                flex-direction:column;
                                align-items: center;
                                justify-content: center;
                                h2 {
                                    font-family: YouSheBiaoTiHei;
                                    color: #fff;
                                    @include res(font-size,0.3rem);
                                }
                                span {
                                    display: block;
                                    @include res(width,0.11rem);
                                    @include res(height,0.02rem);
                                    background-color: #fff;
                                    @include res(margin-top,0.1rem);
                                    @include res(margin-bottom,0.2rem);
                                }
                                h3 {
                                    font-family: YouSheBiaoTiHei;
                                    @include res(font-size,0.18rem);
                                    color: #fff;
                                }
                            }
                            .text {
                                @include res(width,5.24rem,(mmd:67%));
                                .top {
                                    h2 {
                                        color: #1b8cac;
                                    }
                                    .time {
                                        display: none;
                                    }
                                }
                            }
                            .more {
                                .img1 {
                                    display: none;
                                }
                                .img2 {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .news-nav {
        display: flex;
        @include res(margin-top,0.2rem);
        a {
            display: block;
            @include res(font-size,16px,(14 / 16));
            @include res(width,108px);
            @include res(height,38px);
            border: solid 1px #595757;
            @include res(line-height,38px);
            color: #474747;
            text-align: center;
            @include res(margin-right,0.13rem);
            &:last-child {
                margin-right: 0;
            }
            &.active,&:hover {
                border-color: #fff;
                background: #fff;
                color: #1b8cac;
            }
        }
        &.news-nav1 {
            @include res(display,none,(xs:flex));
            align-items: center;
            justify-content: center;
            margin-top: 20px;
        }
        &.news-nav2 {
            @include res(display,flex,(xs:none));
        }
    }
}
.page6_bg {
    @include res(height,100%,(xs:500px));
    .bg {
        @include res(position,absolute);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .content {
        position: relative;
        @include res(width,13.8rem,(mmd:16rem,xs:96%));
        @include res(margin-left,2.7rem,(mmd:1.6rem,xs:auto));
        @include res(margin-right,0,(mmd:1.6rem,xs:auto));
        @include res(margin-top,2.47rem,(xs:30px));
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-wrap: wrap;
        .left {
            .title {
                h2 {
                    font-family: SourceHanSansCN-Regular;
                    @include res(font-size,0.26rem,(xs:20px));
                    color: #ffffff;
                }
                span {
                    @include res(margin-top,0.25rem);
                    display: block;
                    @include res(width,0.23rem);
                    @include res(height,0.03rem);
                    background-color: #ffffff;
                }
            }
            .phone {
                @include res(margin-top,0.54rem);
                display: flex;
                img {
                    margin-right: 10px;
                    @include res(width,fit-content,(xs:20px));
                }
                p {
                    font-family: YouSheBiaoTiHei;
                    @include res(font-size,0.3rem);
                    color: #ffffff;
                }
            }
            dl {
                @include res(margin-top,0.27rem);
                dt {

                    font-family: SourceHanSansCN-Regular;
                    @include res(font-size,0.18rem);
                    color: #ffffff;
                    @include res(margin-bottom,0.15rem);
                }
                dd {
                    font-family: SourceHanSansCN-Light;
                    @include res(font-size,16px,(14 / 16));
                    color: #ffffff;
                    @include res(margin-bottom,0.1rem);
                    &:last-child {
                        margin-bottom: 0;
                    }
                    
                }
            }
            .ewm {
                @include res(margin,0,(xs:auto));
                @include res(margin-top,0.65rem,(xs:30px));
                @include res(margin-bottom,0,(xs:30px));
                display: flex;
                width: fit-content;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                p {
                    @include res(margin-top,0.1rem);
                    font-family: SourceHanSansCN-Light;
                    @include res(font-size,16px,(14 / 16));
                    color: #ffffff;
                }
            }
        }
        .right {
            @include res(width,530px,(mmd:450px));
            display: flex;
            flex-wrap: wrap;
            dl {
                @include res(display,block,(xs:none));
                width: fit-content;
                @include res(margin-right,1rem);
                dt {
                    font-family: SourceHanSansCN-Regular;
                    @include res(font-size,0.14rem);
                    color: #ffffff;
                }
                span {
                    @include res(margin-top,0.14rem);
                    @include res(margin-bottom,0.18rem);
                    display: block;
                    @include res(width,16px);
                    @include res(height,0.01rem);
                    background-color: #ffffff;
                    opacity: .5;
                }
                dd {
                    font-family: SourceHanSansCN-Light;
                    @include res(font-size,0.12rem);
                    @include res(margin-bottom,0.1rem);
                    color: #ffffff;
                    opacity: .7;
                    transition: all .3s;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:hover {
                        opacity: 1;
                    }
                }
                &:nth-child(4) {
                    @include res(margin-right,0);
                }
            }
            .title {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                h2 {
                    font-family: YouSheBiaoTiHei;
                    @include res(font-size,0.3rem);
                    color: #ffffff;
                }
                img {
                    @include res(width,2rem,(xs:fit-content));
                    @include res(margin-left,0.2rem);
                }
            }
        }
    }
    .pro6-bottom {
        @include res(position,absolute);
        bottom: 0;
        left: 0;
        width: 100%;
        @include res(height,0.76rem,(xs:auto));
        background-color: #1b8cac;
        @include res(line-height,0.76rem,(xs:normal));
        p {
            font-family: SourceHanSansCN-Normal;
            font-size: 12px;
            color: #ffffff;
            letter-spacing: 1px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            text-align: center;
            a {
                display: block;
                @include res(margin-left,10px);
            }
        }
    }
}