.hamburger {
  display: block;
  cursor: pointer;
  background-color: transparent;
  overflow: visible;
  position: relative;
  width: 28px;
  height: 19px;
}

.hamburger > div, .hamburger > view {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}

.hamburger > div, .hamburger > div::before, .hamburger > div::after, .hamburger > view, .hamburger > view::before, .hamburger > view::after {
  width: 28px;
  height: 3px;
  background-color: #1B8CAC;
  border-radius: 0;
  position: absolute;
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger > div::before, .hamburger > div::after, .hamburger > view::before, .hamburger > view::after {
  content: "";
  display: block;
}

.hamburger > div::before, .hamburger > view::before {
  top: -8px;
}

.hamburger > div::after, .hamburger > view::after {
  bottom: -8px;
}

.hamburger.hamburger-1 > div, .hamburger.hamburger-1 > view {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-1 > div::after, .hamburger.hamburger-1 > view::after {
  top: -16px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear, background-color 0.3s linear;
}

.hamburger.hamburger-1 > div::before, .hamburger.hamburger-1 > view::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-1.active > div, .hamburger.hamburger-1.active view {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-1.active > div::after, .hamburger.hamburger-1.active view::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear, background-color 0.3s linear;
}

.hamburger.hamburger-1.active > div::before, .hamburger.hamburger-1.active view::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-2 > div, .hamburger.hamburger-2 > view {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-2 > div::after, .hamburger.hamburger-2 > view::after {
  top: -16px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear, background-color 0.3s linear;
}

.hamburger.hamburger-2 > div::before, .hamburger.hamburger-2 > view::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-2.active > div, .hamburger.hamburger-2.active > view {
  transform: translate3d(0, -8px, 0) rotate(45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-2.active > div::after, .hamburger.hamburger-2.active > view::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear, background-color 0.3s linear;
}

.hamburger.hamburger-2.active > div::before, .hamburger.hamburger-2.active > view::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-3 > div, .hamburger.hamburger-3 > view {
  top: 0;
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  margin-top: 0;
}

.hamburger.hamburger-3 > div::before, .hamburger.hamburger-3 > view::before {
  top: 8px;
  transition: opacity 0.15s 0.4s ease, background-color 0.3s linear;
}

.hamburger.hamburger-3 > div::after, .hamburger.hamburger-3 > view::after {
  top: 16px;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), background-color 0.3s linear;
}

.hamburger.hamburger-3.active > div, .hamburger.hamburger-3.active > view {
  transform: translate3d(0, 8px, 0) rotate(135deg);
  transition-delay: .1s;
}

.hamburger.hamburger-3.active > div::before, .hamburger.hamburger-3.active > view::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger.hamburger-3.active > div:after, .hamburger.hamburger-3.active > view:after {
  transform: translate3d(0, -16px, 0) rotate(-270deg);
  transition-delay: .1s;
}

.hamburger.hamburger-4 > div, .hamburger.hamburger-4 > view {
  top: 0;
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  margin-top: 0;
}

.hamburger.hamburger-4 > div::before, .hamburger.hamburger-4 > view::before {
  top: 8px;
  transition: opacity 0.15s 0.4s ease, background-color 0.3s linear;
}

.hamburger.hamburger-4 > div::after, .hamburger.hamburger-4 > view::after {
  top: 16px;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), background-color 0.3s linear;
}

.hamburger.hamburger-4.active > div, .hamburger.hamburger-4.active > view {
  transform: translate3d(0, 8px, 0) rotate(-135deg);
  transition-delay: .1s;
}

.hamburger.hamburger-4.active > div::before, .hamburger.hamburger-4.active > view::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger.hamburger-4.active > div:after, .hamburger.hamburger-4.active > view:after {
  transform: translate3d(0, -16px, 0) rotate(270deg);
  transition-delay: .1s;
}

.hamburger.hamburger-5 > div, .hamburger.hamburger-5 > view {
  margin-top: -2px;
  transition: background-color 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5 > div::before, .hamburger.hamburger-5 > view::before {
  left: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5 > div::after, .hamburger.hamburger-5 > view::after {
  top: 8px;
  right: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5.active > div, .hamburger.hamburger-5.active > view {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger.hamburger-5.active > div::before, .hamburger.hamburger-5.active > view::before {
  left: -56px;
  top: -56px;
  transform: translate3d(56px, 56px, 0) rotate(45deg);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-5.active > div::after, .hamburger.hamburger-5.active > view::after {
  right: -56px;
  top: -56px;
  transform: translate3d(-56px, 56px, 0) rotate(-45deg);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-6 > div, .hamburger.hamburger-6 > view {
  margin-top: -2px;
  transition: background-color 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6 > div::before, .hamburger.hamburger-6 > view::before {
  left: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6 > div::after, .hamburger.hamburger-6 > view::after {
  top: 8px;
  right: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6.active > div, .hamburger.hamburger-6.active > view {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger.hamburger-6.active > div::before, .hamburger.hamburger-6.active > view::before {
  left: -56px;
  top: 56px;
  transform: translate3d(56px, -56px, 0) rotate(-45deg);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-6.active > div::after, .hamburger.hamburger-6.active > view::after {
  right: -56px;
  top: 56px;
  transform: translate3d(-56px, -56px, 0) rotate(45deg);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-7 > div, .hamburger.hamburger-7 > view {
  top: 0;
  margin-top: 0;
}

.hamburger.hamburger-7 > div::before, .hamburger.hamburger-7 > view::before {
  top: 8px;
  transition-property: transform, opacity, background-color;
  transition-timing-function: ease;
  transition-duration: .2s;
}

.hamburger.hamburger-7 > div::after, .hamburger.hamburger-7 > view::after {
  top: 16px;
}

.hamburger.hamburger-7.active > div, .hamburger.hamburger-7.active > view {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger.hamburger-7.active > div::before, .hamburger.hamburger-7.active > view::before {
  transform: rotate(-45deg) translate3d(-4px, -5px, 0);
  opacity: 0;
}

.hamburger.hamburger-7.active > div::after, .hamburger.hamburger-7.active > view::after {
  transform: translate3d(0, -16px, 0) rotate(-90deg);
}

.hamburger.hamburger-8 > div, .hamburger.hamburger-8 > view {
  top: 0;
  margin-top: 0;
}

.hamburger.hamburger-8 > div::before, .hamburger.hamburger-8 > view::before {
  top: 8px;
  transition-property: transform, opacity, background-color;
  transition-timing-function: ease;
  transition-duration: .2s;
}

.hamburger.hamburger-8 > div::after, .hamburger.hamburger-8 > view::after {
  top: 16px;
}

.hamburger.hamburger-8.active > div, .hamburger.hamburger-8.active > view {
  transform: translate3d(0, 8px, 0) rotate(-45deg);
}

.hamburger.hamburger-8.active > div::before, .hamburger.hamburger-8.active > view::before {
  transform: rotate(45deg) translate3d(-4px, -5px, 0);
  opacity: 0;
}

.hamburger.hamburger-8.active > div::after, .hamburger.hamburger-8.active > view::after {
  transform: translate3d(0, -16px, 0) rotate(90deg);
}

.hamburger.hamburger-9 > div, .hamburger.hamburger-9 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-9 > div::before, .hamburger.hamburger-9 > view::before {
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-9 > div::after, .hamburger.hamburger-9 > view::after {
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-9.active > div, .hamburger.hamburger-9.active > view {
  transform: rotate(225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-9.active > div::before, .hamburger.hamburger-9.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease-out, opacity 0.1s 0.14s ease-out, background-color 0.3s linear;
}

.hamburger.hamburger-9.active > div::after, .hamburger.hamburger-9.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-10 > div, .hamburger.hamburger-10 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-10 > div::before, .hamburger.hamburger-10 > view::before {
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-10 > div::after, .hamburger.hamburger-10 > view::after {
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-10.active > div, .hamburger.hamburger-10.active > view {
  transform: rotate(-225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-10.active > div::before, .hamburger.hamburger-10.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease-out, opacity 0.1s 0.14s ease-out, background-color 0.3s linear;
}

.hamburger.hamburger-10.active > div::after, .hamburger.hamburger-10.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-11 > div, .hamburger.hamburger-11 > view {
  margin-top: -2px;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-11 > div::before, .hamburger.hamburger-11 > view::before {
  transition: top 0.1s 0.14s ease, opacity 0.1s ease, background-color 0.3s linear;
}

.hamburger.hamburger-11 > div::after, .hamburger.hamburger-11 > view::after {
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-11.active > div, .hamburger.hamburger-11.active > view {
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-11.active > div::before, .hamburger.hamburger-11.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease, opacity 0.1s 0.14s ease, background-color 0.3s linear;
}

.hamburger.hamburger-11.active > div::after, .hamburger.hamburger-11.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-12 > div, .hamburger.hamburger-12 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-12 > div::before, .hamburger.hamburger-12 > div::after, .hamburger.hamburger-12 > view::before, .hamburger.hamburger-12 > view::after {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger.hamburger-12 > div::before, .hamburger.hamburger-12 > view::before {
  transition-property: top, opacity, background-color;
}

.hamburger.hamburger-12 > div::after, .hamburger.hamburger-12 > view::after {
  transition-property: bottom, transform, background-color;
}

.hamburger.hamburger-12.active > div, .hamburger.hamburger-12.active > view {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-12.active > div::before, .hamburger.hamburger-12.active > div::after, .hamburger.hamburger-12.active > view::before, .hamburger.hamburger-12.active > view::after {
  transition-delay: 0s;
}

.hamburger.hamburger-12.active > div::before, .hamburger.hamburger-12.active > view::before {
  top: 0;
  opacity: 0;
}

.hamburger.hamburger-12.active > div::after, .hamburger.hamburger-12.active > view::after {
  bottom: 0;
  transform: rotate(90deg);
}

.hamburger.hamburger-13 > div, .hamburger.hamburger-13 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-13 > div::before, .hamburger.hamburger-13 > div::after, .hamburger.hamburger-13 > view::before, .hamburger.hamburger-13 > view::after {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger.hamburger-13 > div::before, .hamburger.hamburger-13 > view::before {
  transition-property: top, opacity;
}

.hamburger.hamburger-13 > div::after, .hamburger.hamburger-13 > view::after {
  transition-property: bottom, transform, background-color;
}

.hamburger.hamburger-13.active > div, .hamburger.hamburger-13.active > view {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-13.active > div::before, .hamburger.hamburger-13.active > div::after, .hamburger.hamburger-13.active > view::before, .hamburger.hamburger-13.active > view::after {
  transition-delay: 0s;
}

.hamburger.hamburger-13.active > div::before, .hamburger.hamburger-13.active > view::before {
  top: 0;
  opacity: 0;
}

.hamburger.hamburger-13.active > div::after, .hamburger.hamburger-13.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
}

.page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  left: 0;
  top: 0;
}

@media screen and (max-width: 768px) {
  .fp-section {
    height: auto !important;
  }
}

#fp-nav {
  display: block;
}

@media (max-width: 1024px) {
  #fp-nav {
    display: none;
  }
}

#fp-nav.right {
  right: 1.6rem;
}

@media (max-width: 1366px) {
  #fp-nav.right {
    right: 1rem;
  }
}

#fp-nav ul li {
  border: 1px solid #fff;
}

#fp-nav.on ul li {
  border: 1px solid #595757;
}

#fp-nav.on ul li .active span {
  background-color: #1b8cac;
}

.page1_bg {
  height: 100%;
  margin-top: 0;
  position: relative;
  background: #f9f9f9;
  background-size: cover;
  overflow: hidden;
}

@media (max-width: 767px) {
  .page1_bg {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .page1_bg {
    margin-top: 70px;
  }
}

.page1_bg .swiper {
  width: 100%;
  height: 100%;
}

.page1_bg .swiper .swiper-slide {
  position: relative;
}

.page1_bg .swiper .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page1_bg .swiper .swiper-slide .text {
  position: absolute;
  color: #ffffff;
}

.page1_bg .swiper .swiper-slide .text h2 {
  font-family: PangMenZhengDao;
  font-size: 0.66rem;
}

.page1_bg .swiper .swiper-slide .text h2 span {
  font-family: SourceHanSansCN-Heavy;
  color: #1b8cac;
}

.page1_bg .swiper .swiper-slide .text p {
  margin-top: 0.08rem;
  font-family: SourceHanSansCN-Normal;
  font-size: 0.24rem;
  letter-spacing: 0.12rem;
}

@media (max-width: 767px) {
  .page1_bg .swiper .swiper-slide .text p {
    font-size: 20px;
  }
}

.page1_bg .swiper .swiper-slide .text .more {
  margin-top: 0.78rem;
  width: 100px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #fff;
  text-align: center;
  color: #fff;
  transition: all .3s;
}

@media (max-width: 767px) {
  .page1_bg .swiper .swiper-slide .text .more {
    margin-top: 30px;
  }
}

.page1_bg .swiper .swiper-slide .text .more:hover {
  background: #1b8cac;
  border-color: #1b8cac;
}

.page1_bg .swiper .swiper-slide .text.text1 {
  top: 3.5rem;
  left: 2.66rem;
}

@media (max-width: 767px) {
  .page1_bg .swiper .swiper-slide .text.text1 {
    top: 100px;
  }
}

@media (max-width: 767px) {
  .page1_bg .swiper .swiper-slide .text.text1 {
    left: 2%;
  }
}

.page1_bg .swiper .swiper-slide .text.text2 {
  top: 4.1rem;
  right: 2.97rem;
}

@media (max-width: 767px) {
  .page1_bg .swiper .swiper-slide .text.text2 {
    top: 130px;
  }
}

@media (max-width: 767px) {
  .page1_bg .swiper .swiper-slide .text.text2 {
    right: 2%;
  }
}

.page1_bg .swiper .swiper-slide .text.text2 h2 {
  color: #1b8cac;
  font-size: 52px;
}

@media (max-width: 1600px) {
  .page1_bg .swiper .swiper-slide .text.text2 h2 {
    font-size: 47.84px;
  }
}

@media (max-width: 1366px) {
  .page1_bg .swiper .swiper-slide .text.text2 h2 {
    font-size: 43.68px;
  }
}

@media (max-width: 1024px) {
  .page1_bg .swiper .swiper-slide .text.text2 h2 {
    font-size: 39.52px;
  }
}

@media (max-width: 991px) {
  .page1_bg .swiper .swiper-slide .text.text2 h2 {
    font-size: 35.36px;
  }
}

@media (max-width: 767px) {
  .page1_bg .swiper .swiper-slide .text.text2 h2 {
    font-size: 31.2px;
  }
}

.page1_bg .swiper .swiper-slide .text.text2 p {
  letter-spacing: normal;
  font-size: 16px;
  line-height: 38px;
}

@media (max-width: 1600px) {
  .page1_bg .swiper .swiper-slide .text.text2 p {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page1_bg .swiper .swiper-slide .text.text2 p {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page1_bg .swiper .swiper-slide .text.text2 p {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page1_bg .swiper .swiper-slide .text.text2 p {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page1_bg .swiper .swiper-slide .text.text2 p {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .page1_bg .swiper .swiper-slide .text.text2 p {
    line-height: 36.48px;
  }
}

@media (max-width: 1366px) {
  .page1_bg .swiper .swiper-slide .text.text2 p {
    line-height: 34.96px;
  }
}

@media (max-width: 1024px) {
  .page1_bg .swiper .swiper-slide .text.text2 p {
    line-height: 33.44px;
  }
}

@media (max-width: 991px) {
  .page1_bg .swiper .swiper-slide .text.text2 p {
    line-height: 31.92px;
  }
}

@media (max-width: 767px) {
  .page1_bg .swiper .swiper-slide .text.text2 p {
    line-height: 30.4px;
  }
}

.page1_bg .swiper .swiper-slide .text.text3 {
  top: 3.1rem;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: fit-content;
}

@media (max-width: 767px) {
  .page1_bg .swiper .swiper-slide .text.text3 {
    top: 140px;
  }
}

.page1_bg .swiper .swiper-slide .text.text3 h2 {
  font-size: 80px;
}

@media (max-width: 1600px) {
  .page1_bg .swiper .swiper-slide .text.text3 h2 {
    font-size: 73.6px;
  }
}

@media (max-width: 1366px) {
  .page1_bg .swiper .swiper-slide .text.text3 h2 {
    font-size: 67.2px;
  }
}

@media (max-width: 1024px) {
  .page1_bg .swiper .swiper-slide .text.text3 h2 {
    font-size: 60.8px;
  }
}

@media (max-width: 991px) {
  .page1_bg .swiper .swiper-slide .text.text3 h2 {
    font-size: 54.4px;
  }
}

@media (max-width: 767px) {
  .page1_bg .swiper .swiper-slide .text.text3 h2 {
    font-size: 48px;
  }
}

.page1_bg .swiper .swiper-slide .text.text3 h2 span {
  color: #fff;
  font-family: PangMenZhengDao;
  margin-right: 0.5rem;
}

.page1_bg .swiper .swiper-slide .text.text3 .more {
  margin-left: auto;
  margin-right: auto;
}

.page1_bg .swiper .swiper-pagination {
  bottom: 85px;
  right: 1.6rem;
  width: fit-content;
  left: auto;
}

@media (max-width: 767px) {
  .page1_bg .swiper .swiper-pagination {
    bottom: 20px;
  }
}

@media (max-width: 767px) {
  .page1_bg .swiper .swiper-pagination {
    right: 60px;
  }
}

.page1_bg .swiper .swiper-pagination .swiper-pagination-bullet {
  width: 37px;
  height: 5px;
  background-color: #ffffff;
  opacity: .5;
  border-radius: 0;
  margin: 0;
  margin-right: 1px;
}

.page1_bg .swiper .swiper-pagination .swiper-pagination-bullet:last-child {
  margin-right: 0;
}

.page1_bg .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
}

.page2_bg {
  height: 100%;
}

@media (max-width: 767px) {
  .page2_bg {
    height: 550px;
  }
}

.page2_bg .bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.page2_bg .content .left {
  position: absolute;
  width: 396px;
  margin: 0;
  margin-top: 0;
  top: 2rem;
  left: 2.7rem;
}

@media (max-width: 767px) {
  .page2_bg .content .left {
    position: static;
  }
}

@media (max-width: 767px) {
  .page2_bg .content .left {
    width: 96%;
  }
}

@media (max-width: 767px) {
  .page2_bg .content .left {
    margin: auto;
  }
}

@media (max-width: 767px) {
  .page2_bg .content .left {
    margin-top: 30px;
  }
}

@media (max-width: 1366px) {
  .page2_bg .content .left {
    top: 3rem;
  }
}

@media (max-width: 767px) {
  .page2_bg .content .left {
    top: 40px;
  }
}

@media (max-width: 1366px) {
  .page2_bg .content .left {
    left: 1.6rem;
  }
}

@media (max-width: 767px) {
  .page2_bg .content .left {
    left: 20px;
  }
}

.page2_bg .content .left p {
  font-family: SourceHanSansCN-Light;
  font-size: 14px;
  line-height: 30px;
  color: #474747;
}

.page2_bg .content .left .more {
  display: block;
  margin-top: 0.3rem;
  width: 100px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #1b8cac;
  text-align: center;
  color: #1b8cac;
  transition: all .3s;
}

.page2_bg .content .left .more:hover {
  color: #fff;
  background: #1b8cac;
  border-color: #1b8cac;
}

.page2_bg .content .right {
  position: absolute;
  width: 218px;
  margin: 0;
  margin-top: 0;
  top: 2.07rem;
  right: 3.72rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .page2_bg .content .right {
    position: static;
  }
}

@media (max-width: 767px) {
  .page2_bg .content .right {
    margin: auto;
  }
}

@media (max-width: 767px) {
  .page2_bg .content .right {
    margin-top: 30px;
  }
}

@media (max-width: 1366px) {
  .page2_bg .content .right {
    top: 3.04rem;
  }
}

@media (max-width: 1366px) {
  .page2_bg .content .right {
    right: 2rem;
  }
}

.page2_bg .content .right li {
  width: 50%;
  height: 0.8rem;
  display: flex;
  position: relative;
  align-items: center;
}

.page2_bg .content .right li a {
  display: block;
  width: fit-content;
}

.page2_bg .content .right li a .pic {
  width: fit-content;
  margin: auto;
}

.page2_bg .content .right li a .pic .img2 {
  display: none;
  transition: all .3s;
}

.page2_bg .content .right li a span {
  margin-top: 7px;
  width: fit-content;
  display: block;
  font-size: 16px;
  line-height: 30px;
  color: #929292;
  transition: all .3s;
}

@media (max-width: 1600px) {
  .page2_bg .content .right li a span {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page2_bg .content .right li a span {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page2_bg .content .right li a span {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page2_bg .content .right li a span {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page2_bg .content .right li a span {
    font-size: 14px;
  }
}

.page2_bg .content .right li a:hover .pic .img1 {
  display: none;
}

.page2_bg .content .right li a:hover .pic .img2 {
  display: block;
  transform: scale(1.1);
}

.page2_bg .content .right li a:hover span {
  color: #1b8cac;
  transform: scale(1.1);
}

.page2_bg .content .right li:nth-child(2n-1) {
  border-right: 1px solid #eee;
  padding-right: 39px;
}

.page2_bg .content .right li:first-child {
  margin-bottom: 48px;
}

.page2_bg .content .right li:nth-child(2) {
  margin-bottom: 48px;
  padding-left: 26px;
}

.page2_bg .content .right li:nth-child(2) .line {
  left: 26px;
}

.page2_bg .content .right li:nth-child(4) {
  padding-left: 39px;
}

.page2_bg .content .right li .line {
  position: absolute;
  width: 80px;
  height: 1px;
  background-color: #eeeeee;
  left: 0;
  bottom: -17px;
}

.page3_bg {
  height: 100%;
  background: url(./images/pro3-bg.png) no-repeat;
  background-size: cover;
}

@media (max-width: 767px) {
  .page3_bg {
    height: 450px;
  }
}

.page3_bg .pro3-nav {
  position: absolute;
  top: 4.9rem;
  left: 4.45rem;
  z-index: 10;
  display: block;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .page3_bg .pro3-nav {
    position: relative;
  }
}

@media (max-width: 767px) {
  .page3_bg .pro3-nav {
    top: 30px;
  }
}

@media (max-width: 767px) {
  .page3_bg .pro3-nav {
    left: 0;
  }
}

@media (max-width: 767px) {
  .page3_bg .pro3-nav {
    display: flex;
  }
}

.page3_bg .pro3-nav li {
  cursor: pointer;
  font-family: SourceHanSansCN-Light;
  font-size: 18px;
  color: #ffffff;
  opacity: 0.5;
  transition: all .3s;
  margin-bottom: 25px;
  margin-right: 0;
  border-left: 1px solid transparent;
  padding-left: 19px;
}

@media (max-width: 767px) {
  .page3_bg .pro3-nav li {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .page3_bg .pro3-nav li {
    margin-right: 10px;
  }
}

.page3_bg .pro3-nav li:last-child {
  margin-bottom: 0;
  margin-right: 0;
}

.page3_bg .pro3-nav li.active {
  opacity: 1;
  border-left: 1px solid #fff;
}

@media (max-width: 767px) {
  .page3_bg .pro3-nav li {
    padding-left: 10px;
  }
}

.page3_bg .pro3-lunbo {
  position: absolute;
  top: 2.1rem;
  left: 0;
  right: 0;
  margin: auto;
}

@media (max-width: 767px) {
  .page3_bg .pro3-lunbo {
    position: relative;
  }
}

@media (max-width: 767px) {
  .page3_bg .pro3-lunbo {
    top: 60px;
  }
}

.page3_bg .pro3-lunbo h2 {
  font-family: SourceHanSansCN-Light;
  font-size: 0.36rem;
  letter-spacing: 3px;
  text-align: center;
  color: #fff;
  margin-bottom: 1.3rem;
}

@media (max-width: 767px) {
  .page3_bg .pro3-lunbo h2 {
    margin-bottom: 30px;
  }
}

.page3_bg .pro3-lunbo h2 span {
  font-family: SourceHanSansCN-Bold;
}

.page3_bg .pro3-lunbo img {
  margin: auto;
  width: fit-content;
}

.page3_bg .pro3-lunbo .pro3-swiper {
  display: none;
}

.page3_bg .pro3-lunbo .pro3-swiper.active {
  display: block;
}

.page3_bg .pro3-lunbo .pro3-swiper .swiper .swiper-slide img {
  margin: auto;
}

.page3_bg .pro3-lunbo .pro3-swiper .swiper-button-next {
  right: 2.7rem;
}

@media (max-width: 1366px) {
  .page3_bg .pro3-lunbo .pro3-swiper .swiper-button-next {
    right: 1.6rem;
  }
}

@media (max-width: 767px) {
  .page3_bg .pro3-lunbo .pro3-swiper .swiper-button-next {
    right: 20px;
  }
}

.page3_bg .pro3-lunbo .pro3-swiper .swiper-button-next .img2 {
  display: none;
}

.page3_bg .pro3-lunbo .pro3-swiper .swiper-button-next:hover .img1, .page3_bg .pro3-lunbo .pro3-swiper .swiper-button-next.active .img1 {
  display: none;
}

.page3_bg .pro3-lunbo .pro3-swiper .swiper-button-next:hover .img2, .page3_bg .pro3-lunbo .pro3-swiper .swiper-button-next.active .img2 {
  display: block;
}

.page3_bg .pro3-lunbo .pro3-swiper .swiper-button-prev {
  left: 2.7rem;
}

@media (max-width: 1366px) {
  .page3_bg .pro3-lunbo .pro3-swiper .swiper-button-prev {
    left: 1.6rem;
  }
}

@media (max-width: 767px) {
  .page3_bg .pro3-lunbo .pro3-swiper .swiper-button-prev {
    left: 20px;
  }
}

.page3_bg .pro3-lunbo .pro3-swiper .swiper-button-prev .img2 {
  display: none;
}

.page3_bg .pro3-lunbo .pro3-swiper .swiper-button-prev:hover .img1, .page3_bg .pro3-lunbo .pro3-swiper .swiper-button-prev.active .img1 {
  display: none;
}

.page3_bg .pro3-lunbo .pro3-swiper .swiper-button-prev:hover .img2, .page3_bg .pro3-lunbo .pro3-swiper .swiper-button-prev.active .img2 {
  display: block;
}

.page4_bg {
  height: 100%;
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .page4_bg {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .page4_bg {
    padding-bottom: 40px;
  }
}

.page4_bg .pro4-bg {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}

@media (max-width: 767px) {
  .page4_bg .pro4-bg {
    position: absolute;
  }
}

.page4_bg .pro4-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;
}

.page4_bg .pro4-bg img.active {
  display: block;
}

.page4_bg .pro4-text {
  position: absolute;
  width: 466px;
  top: 2.7rem;
  left: 2.7rem;
  right: auto;
  margin: 0;
}

@media (max-width: 767px) {
  .page4_bg .pro4-text {
    position: relative;
  }
}

@media (max-width: 767px) {
  .page4_bg .pro4-text {
    width: 96%;
  }
}

@media (max-width: 767px) {
  .page4_bg .pro4-text {
    top: 30px;
  }
}

@media (max-width: 1366px) {
  .page4_bg .pro4-text {
    left: 1.6rem;
  }
}

@media (max-width: 767px) {
  .page4_bg .pro4-text {
    left: 0;
  }
}

@media (max-width: 767px) {
  .page4_bg .pro4-text {
    right: 0;
  }
}

@media (max-width: 767px) {
  .page4_bg .pro4-text {
    margin: auto;
  }
}

.page4_bg .pro4-text .text {
  display: none;
}

.page4_bg .pro4-text .text.active {
  display: block;
}

.page4_bg .pro4-text .text h2 {
  font-family: SourceHanSansCN-Medium;
  font-size: 20px;
  color: #474747;
}

.page4_bg .pro4-text .text p {
  margin-top: 15px;
  font-family: SourceHanSansCN-Light;
  font-size: 14px;
  line-height: 30px;
  color: #474747;
}

.page4_bg .pro4-text .text .more {
  display: block;
  margin-top: 0.4rem;
  width: 100px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #1b8cac;
  text-align: center;
  color: #1b8cac;
  transition: all .3s;
}

.page4_bg .pro4-text .text .more:hover {
  color: #fff;
  background: #1b8cac;
  border-color: #1b8cac;
}

.page4_bg .pro4-nav {
  position: absolute;
  bottom: 1.53rem;
  left: 2.7rem;
  right: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 767px) {
  .page4_bg .pro4-nav {
    bottom: 20px;
  }
}

@media (max-width: 1366px) {
  .page4_bg .pro4-nav {
    left: 1.6rem;
  }
}

@media (max-width: 767px) {
  .page4_bg .pro4-nav {
    left: 0;
  }
}

@media (max-width: 767px) {
  .page4_bg .pro4-nav {
    right: 0;
  }
}

@media (max-width: 767px) {
  .page4_bg .pro4-nav {
    justify-content: center;
  }
}

.page4_bg .pro4-nav li {
  width: 85px;
  height: 85px;
  margin-right: 0.14rem;
  background: #fff;
  transition: all .3s;
}

.page4_bg .pro4-nav li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.page4_bg .pro4-nav li .pic {
  margin-bottom: 0.1rem;
}

.page4_bg .pro4-nav li .pic .img2 {
  display: none;
}

.page4_bg .pro4-nav li span {
  display: block;
  text-align: center;
  font-size: 16px;
  color: #474747;
}

@media (max-width: 1600px) {
  .page4_bg .pro4-nav li span {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page4_bg .pro4-nav li span {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page4_bg .pro4-nav li span {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page4_bg .pro4-nav li span {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page4_bg .pro4-nav li span {
    font-size: 14px;
  }
}

.page4_bg .pro4-nav li.active {
  background: #1b8cac;
}

.page4_bg .pro4-nav li.active .pic .img1 {
  display: none;
}

.page4_bg .pro4-nav li.active .pic .img2 {
  display: block;
}

.page4_bg .pro4-nav li.active span {
  color: #fff;
}

.page4_bg .pro4-nav li:last-child {
  margin-right: 0;
}

.page5_bg {
  background: url(./images/pro5-bg.png) no-repeat;
  background-size: cover;
  height: 100%;
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .page5_bg {
    height: auto;
  }
}

@media (max-width: 767px) {
  .page5_bg {
    padding-bottom: 30px;
  }
}

.page5_bg .pro5-news {
  position: absolute;
  left: 2.7rem;
  top: 2.88rem;
  width: 13.8rem;
  transform: translateY(0);
}

@media (max-width: 767px) {
  .page5_bg .pro5-news {
    position: static;
  }
}

@media (max-width: 1366px) {
  .page5_bg .pro5-news {
    left: 1.6rem;
  }
}

@media (max-width: 1366px) {
  .page5_bg .pro5-news {
    top: 50%;
  }
}

@media (max-width: 1366px) {
  .page5_bg .pro5-news {
    width: 16rem;
  }
}

@media (max-width: 767px) {
  .page5_bg .pro5-news {
    width: 96%;
  }
}

@media (max-width: 1366px) {
  .page5_bg .pro5-news {
    transform: translateY(-50%);
  }
}

@media (max-width: 767px) {
  .page5_bg .pro5-news {
    transform: translateY(0);
  }
}

.page5_bg .pro5-news .news.active {
  display: flex;
}

.page5_bg .pro5-news .news .pic {
  display: block;
  width: 6.9rem;
  height: 5.09rem;
}

@media (max-width: 767px) {
  .page5_bg .pro5-news .news .pic {
    display: none;
  }
}

@media (max-width: 1366px) {
  .page5_bg .pro5-news .news .pic {
    width: 50%;
  }
}

.page5_bg .pro5-news .news .pic a {
  width: 100%;
  height: 100%;
  display: none;
}

.page5_bg .pro5-news .news .pic a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page5_bg .pro5-news .news .pic a.active {
  display: block;
}

.page5_bg .pro5-news .news ul {
  width: 7.83rem;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
  margin-top: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

@media (max-width: 1366px) {
  .page5_bg .pro5-news .news ul {
    width: 56%;
  }
}

@media (max-width: 767px) {
  .page5_bg .pro5-news .news ul {
    width: 96%;
  }
}

@media (max-width: 767px) {
  .page5_bg .pro5-news .news ul {
    position: static;
  }
}

@media (max-width: 767px) {
  .page5_bg .pro5-news .news ul {
    margin: auto;
  }
}

@media (max-width: 767px) {
  .page5_bg .pro5-news .news ul {
    margin-top: 30px;
  }
}

.page5_bg .pro5-news .news ul li {
  width: 6.28rem;
  padding-bottom: 0.3rem;
  margin-bottom: 0.34rem;
  border-bottom: 1px solid #595757;
  transition: all .5s;
}

@media (max-width: 1366px) {
  .page5_bg .pro5-news .news ul li {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .page5_bg .pro5-news .news ul li {
    width: 100%;
  }
}

.page5_bg .pro5-news .news ul li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page5_bg .pro5-news .news ul li a .text {
  width: 5.24rem;
}

@media (max-width: 1366px) {
  .page5_bg .pro5-news .news ul li a .text {
    width: 80%;
  }
}

.page5_bg .pro5-news .news ul li a .text .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page5_bg .pro5-news .news ul li a .text .top h2 {
  width: 3.8rem;
  font-family: SourceHanSansCN-Regular;
  font-size: 0.18rem;
  color: #474747;
  margin-bottom: 5px;
}

@media (max-width: 1366px) {
  .page5_bg .pro5-news .news ul li a .text .top h2 {
    width: 72.5%;
  }
}

.page5_bg .pro5-news .news ul li a .text .top .time {
  font-family: SourceHanSansCN-Light;
  font-size: 0.14rem;
  color: #5f5f5f;
}

.page5_bg .pro5-news .news ul li a .text p {
  font-family: SourceHanSansCN-Light;
  font-size: 0.14rem;
  line-height: 24px;
  color: #5f5f5f;
  opacity: 0.8;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.page5_bg .pro5-news .news ul li a .more .img2 {
  display: none;
}

.page5_bg .pro5-news .news ul li a .time-box {
  display: none;
}

.page5_bg .pro5-news .news ul li:last-child {
  margin-bottom: 0;
  border: none;
}

.page5_bg .pro5-news .news ul li.active {
  width: 7.83rem;
  height: 1.6rem;
  background-color: #ffffff;
  border: none;
  box-sizing: border-box;
  padding-left: 0.13rem;
  padding-right: 0.28rem;
}

@media (max-width: 1366px) {
  .page5_bg .pro5-news .news ul li.active {
    width: 100%;
  }
}

.page5_bg .pro5-news .news ul li.active a .time-box {
  display: block;
  width: 1.19rem;
  height: 1.45rem;
  background-color: #1b8cac;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page5_bg .pro5-news .news ul li.active a .time-box h2 {
  font-family: YouSheBiaoTiHei;
  color: #fff;
  font-size: 0.3rem;
}

.page5_bg .pro5-news .news ul li.active a .time-box span {
  display: block;
  width: 0.11rem;
  height: 0.02rem;
  background-color: #fff;
  margin-top: 0.1rem;
  margin-bottom: 0.2rem;
}

.page5_bg .pro5-news .news ul li.active a .time-box h3 {
  font-family: YouSheBiaoTiHei;
  font-size: 0.18rem;
  color: #fff;
}

.page5_bg .pro5-news .news ul li.active a .text {
  width: 5.24rem;
}

@media (max-width: 1366px) {
  .page5_bg .pro5-news .news ul li.active a .text {
    width: 67%;
  }
}

.page5_bg .pro5-news .news ul li.active a .text .top h2 {
  color: #1b8cac;
}

.page5_bg .pro5-news .news ul li.active a .text .top .time {
  display: none;
}

.page5_bg .pro5-news .news ul li.active a .more .img1 {
  display: none;
}

.page5_bg .pro5-news .news ul li.active a .more .img2 {
  display: block;
}

.page5_bg .news-nav {
  display: flex;
  margin-top: 0.2rem;
}

.page5_bg .news-nav a {
  display: block;
  font-size: 16px;
  width: 108px;
  height: 38px;
  border: solid 1px #595757;
  line-height: 38px;
  color: #474747;
  text-align: center;
  margin-right: 0.13rem;
}

@media (max-width: 1600px) {
  .page5_bg .news-nav a {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page5_bg .news-nav a {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page5_bg .news-nav a {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page5_bg .news-nav a {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page5_bg .news-nav a {
    font-size: 14px;
  }
}

.page5_bg .news-nav a:last-child {
  margin-right: 0;
}

.page5_bg .news-nav a.active, .page5_bg .news-nav a:hover {
  border-color: #fff;
  background: #fff;
  color: #1b8cac;
}

.page5_bg .news-nav.news-nav1 {
  display: none;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .page5_bg .news-nav.news-nav1 {
    display: flex;
  }
}

.page5_bg .news-nav.news-nav2 {
  display: flex;
}

@media (max-width: 767px) {
  .page5_bg .news-nav.news-nav2 {
    display: none;
  }
}

.page6_bg {
  height: 100%;
}

@media (max-width: 767px) {
  .page6_bg {
    height: 500px;
  }
}

.page6_bg .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page6_bg .content {
  position: relative;
  width: 13.8rem;
  margin-left: 2.7rem;
  margin-right: 0;
  margin-top: 2.47rem;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 1366px) {
  .page6_bg .content {
    width: 16rem;
  }
}

@media (max-width: 767px) {
  .page6_bg .content {
    width: 96%;
  }
}

@media (max-width: 1366px) {
  .page6_bg .content {
    margin-left: 1.6rem;
  }
}

@media (max-width: 767px) {
  .page6_bg .content {
    margin-left: auto;
  }
}

@media (max-width: 1366px) {
  .page6_bg .content {
    margin-right: 1.6rem;
  }
}

@media (max-width: 767px) {
  .page6_bg .content {
    margin-right: auto;
  }
}

@media (max-width: 767px) {
  .page6_bg .content {
    margin-top: 30px;
  }
}

.page6_bg .content .left .title h2 {
  font-family: SourceHanSansCN-Regular;
  font-size: 0.26rem;
  color: #ffffff;
}

@media (max-width: 767px) {
  .page6_bg .content .left .title h2 {
    font-size: 20px;
  }
}

.page6_bg .content .left .title span {
  margin-top: 0.25rem;
  display: block;
  width: 0.23rem;
  height: 0.03rem;
  background-color: #ffffff;
}

.page6_bg .content .left .phone {
  margin-top: 0.54rem;
  display: flex;
}

.page6_bg .content .left .phone img {
  margin-right: 10px;
  width: fit-content;
}

@media (max-width: 767px) {
  .page6_bg .content .left .phone img {
    width: 20px;
  }
}

.page6_bg .content .left .phone p {
  font-family: YouSheBiaoTiHei;
  font-size: 0.3rem;
  color: #ffffff;
}

.page6_bg .content .left dl {
  margin-top: 0.27rem;
}

.page6_bg .content .left dl dt {
  font-family: SourceHanSansCN-Regular;
  font-size: 0.18rem;
  color: #ffffff;
  margin-bottom: 0.15rem;
}

.page6_bg .content .left dl dd {
  font-family: SourceHanSansCN-Light;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 0.1rem;
}

@media (max-width: 1600px) {
  .page6_bg .content .left dl dd {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page6_bg .content .left dl dd {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page6_bg .content .left dl dd {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page6_bg .content .left dl dd {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page6_bg .content .left dl dd {
    font-size: 14px;
  }
}

.page6_bg .content .left dl dd:last-child {
  margin-bottom: 0;
}

.page6_bg .content .left .ewm {
  margin: 0;
  margin-top: 0.65rem;
  margin-bottom: 0;
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 767px) {
  .page6_bg .content .left .ewm {
    margin: auto;
  }
}

@media (max-width: 767px) {
  .page6_bg .content .left .ewm {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .page6_bg .content .left .ewm {
    margin-bottom: 30px;
  }
}

.page6_bg .content .left .ewm p {
  margin-top: 0.1rem;
  font-family: SourceHanSansCN-Light;
  font-size: 16px;
  color: #ffffff;
}

@media (max-width: 1600px) {
  .page6_bg .content .left .ewm p {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page6_bg .content .left .ewm p {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page6_bg .content .left .ewm p {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page6_bg .content .left .ewm p {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page6_bg .content .left .ewm p {
    font-size: 14px;
  }
}

.page6_bg .content .right {
  width: 530px;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 1366px) {
  .page6_bg .content .right {
    width: 450px;
  }
}

.page6_bg .content .right dl {
  display: block;
  width: fit-content;
  margin-right: 1rem;
}

@media (max-width: 767px) {
  .page6_bg .content .right dl {
    display: none;
  }
}

.page6_bg .content .right dl dt {
  font-family: SourceHanSansCN-Regular;
  font-size: 0.14rem;
  color: #ffffff;
}

.page6_bg .content .right dl span {
  margin-top: 0.14rem;
  margin-bottom: 0.18rem;
  display: block;
  width: 16px;
  height: 0.01rem;
  background-color: #ffffff;
  opacity: .5;
}

.page6_bg .content .right dl dd {
  font-family: SourceHanSansCN-Light;
  font-size: 0.12rem;
  margin-bottom: 0.1rem;
  color: #ffffff;
  opacity: .7;
  transition: all .3s;
}

.page6_bg .content .right dl dd:last-child {
  margin-bottom: 0;
}

.page6_bg .content .right dl dd:hover {
  opacity: 1;
}

.page6_bg .content .right dl:nth-child(4) {
  margin-right: 0;
}

.page6_bg .content .right .title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.page6_bg .content .right .title h2 {
  font-family: YouSheBiaoTiHei;
  font-size: 0.3rem;
  color: #ffffff;
}

.page6_bg .content .right .title img {
  width: 2rem;
  margin-left: 0.2rem;
}

@media (max-width: 767px) {
  .page6_bg .content .right .title img {
    width: fit-content;
  }
}

.page6_bg .pro6-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.76rem;
  background-color: #1b8cac;
  line-height: 0.76rem;
}

@media (max-width: 767px) {
  .page6_bg .pro6-bottom {
    height: auto;
  }
}

@media (max-width: 767px) {
  .page6_bg .pro6-bottom {
    line-height: normal;
  }
}

.page6_bg .pro6-bottom p {
  font-family: SourceHanSansCN-Normal;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}

.page6_bg .pro6-bottom p a {
  display: block;
  margin-left: 10px;
}
